// extracted by mini-css-extract-plugin
export var active = "right-module--active--YsrXf";
export var arrow = "right-module--arrow--QcsvK";
export var cover = "right-module--cover--WUpZA";
export var divider = "right-module--divider--gAJ6U";
export var illustration = "right-module--illustration--YIHaA";
export var illustration2 = "right-module--illustration2--yJTZn";
export var imageContainer = "right-module--imageContainer--4VIzF";
export var imageFlex = "right-module--imageFlex--YmvoC";
export var intro = "right-module--intro--p+gmB";
export var menu = "right-module--menu--Fcp0U";
export var menuButton = "right-module--menuButton--wTyFY";
export var note = "right-module--note--QGkdh";
export var otherLink = "right-module--otherLink--ZneEz";
export var otherLink2 = "right-module--otherLink2--9bMjy";
export var otherSection = "right-module--otherSection--b-hAO";
export var otherText = "right-module--otherText--qXeB1";
export var playIntro = "right-module--playIntro--ig6XR";
export var playIntro2 = "right-module--playIntro2--XLqn0";
export var playProjectContainer = "right-module--playProjectContainer--a+lkj";
export var playSection = "right-module--playSection--FhhjX";
export var project = "right-module--project--416gg";
export var projectDescription = "right-module--projectDescription--IeB6i";
export var projectDescriptionPrint = "right-module--projectDescriptionPrint--NxnuE";
export var projectName = "right-module--projectName--THSDf";
export var projectNamePrint = "right-module--projectNamePrint--ZffXp";
export var projectSection = "right-module--projectSection--MMIXS";
export var subTitle = "right-module--subTitle--NUzn5";
export var subintro = "right-module--subintro--C-0DU";
export var transition = "right-module--transition--vOxcZ";
export var wrapper = "right-module--wrapper--WOS6V";
export var year = "right-module--year--UKMC8";